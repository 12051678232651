.Block {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.Head {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Info {
  display: flex;
  gap: 100px;
  font-size: 16px;
  font-weight: bold;

  > div {
    display: flex;
    gap: 20px;
  }

  &Label {

  }
}

.Body {
  display: flex;
  gap: 10px;
}

.Adr {
  flex: 0 0 420px;

  display: flex;
  flex-direction: column;
  gap: 10px;


  &Block {
    border-radius: 10px;
    background-color: #EEF0FC;
    height: 160px;
    padding: 30px;
    position: relative;
    font-size: 15px;
    color: #111634;
    display: flex;
    gap: 23px;
    flex-direction: column;

    &Label {
      color: #9294A9;
      margin-bottom: 4px;
    }


    &Selected {
      background: linear-gradient(93.94deg, #093686, #1950b0 23.33%, #265fc4 64.28%, #0b3f9b 99.53%);
      color: #fff;

      .AdrBlockLabel {
        color: #fff;
      }

      .Scheme {
        color: #fff;
      }
    }

  }

  &Bottom {
    border-radius: 10px;
    background-color: #EEF0FC;
    height: 160px;
    padding: 30px;
    position: relative;
    font-size: 15px;
    color: #111634;
    display: flex;
    gap: 15px;
    flex-direction: column;

    &News {
      display: flex;
      justify-content: space-between;


    }

    &Soc {
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;
        gap: 12px;
      }
    }
  }
}

.Map {
  width: 100%;
}

.Scheme {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  color: #9294A9;
}

.Read {
  display: flex;
  gap: 5px;

  img {
    width: 80px;
  }
}

.Meta {
  color: #9198AD;
  font-size: 12px;
  line-height: initial;
  position: absolute;
  left: 30px;
  bottom: 20px;
  width: 80%;
}

@media only screen and (max-width: 991px) {
  .Body {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .Head {
    flex-direction: column;
  }

}